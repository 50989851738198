<template>
  <div class="main">
    <dia-logliu :show="dailogshow" :options="dailogoptions"></dia-logliu>
    <common-top
      title="修改手机号"
      @search="searchUserStatus = true"
      :isShowSearch="isUserPm"
    ></common-top>

    <Modal v-model="editModal" width="360">
      <p slot="header" style="color: #f60; text-align: center">
        <Icon type="ios-information-circle"></Icon>
        <span>修改提示</span>
      </p>
      <div style="text-align: left; font-weight: bold">
        <p style="margin: 10px 0; color: #515a6e">
          注意 该操作会将原手机号
          <span style="color: #e91a1a"> 【 {{ wxUser.mobile }}】 </span>删除
        </p>
        <p style="color: #515a6e">
          手机号确认
          <span style="color: green"> 【 {{ mobile }} 】 </span>
        </p>
      </div>
      <div slot="footer">
        <Button type="error" size="large" long @click="editMobile"
          >确认修改</Button
        >
      </div>
    </Modal>

    <loading-cmp v-if="showLoad"></loading-cmp>
    <div class="scrollDiv" v-show="isUserPm" style="margin-top: 10px">
      <div v-show="searchUserStatus">
        <user-list
          :isNeedMobile="true"
          @searchBack="searchBack"
          @chooseItem="chooseItem"
        >
        </user-list>
      </div>
      <div v-show="!searchUserStatus">
        <div class="formItem">
          <div class="formItemTitle">姓名</div>
          <div class="detail">
            <Input
              width="200px"
              type="text"
              disabled
              v-model="wxUser.name"
              placeholder="姓名"
            >
            </Input>
          </div>
        </div>
        <div class="formItem">
          <div class="formItemTitle">旧手机号</div>
          <div class="detail">
            <Input
              width="200px"
              type="text"
              disabled
              v-model="wxUser.mobile"
              placeholder=""
            >
            </Input>
          </div>
        </div>

        <div class="formItem">
          <div class="formItemTitle">手机号*</div>
          <div class="detail">
            <Input width="200px" type="text" v-model="mobile" placeholder="">
            </Input>
          </div>
        </div>
        <div style="padding: 10px 20px" @click="editUserCofirm">
          <div class="buttonLarge">修改手机号</div>
        </div>

        <div>
          <div style="width: 98%; margin: 0 auto; text-align: left">
            <i style="">
              <svg
                t="1646638068111"
                class="icon"
                viewBox="0 0 1024 1024"
                version="1.1"
                p-id="4385"
                width="14"
                height="14"
              >
                <path
                  d="M512 0C229.215086 0 0 229.231543 0 512c0 282.784914 229.215086 512 512 512s512-229.215086 512-512C1024 229.231543 794.786743 0 512 0zM512 950.857143C269.999543 950.857143 73.142857 754.000457 73.142857 512 73.142857 270.017829 269.999543 73.142857 512 73.142857s438.857143 196.874971 438.857143 438.857143C950.857143 754.000457 754.000457 950.857143 512 950.857143z"
                  p-id="4386"
                  fill="#666"
                ></path>
                <path
                  d="M585.142857 402.285714 475.428571 402.285714 438.857143 402.285714 402.285714 402.285714 402.285714 438.857143 438.857143 438.857143 438.857143 768 402.285714 768 402.285714 804.571429 621.714286 804.571429 621.714286 768 585.142857 768Z"
                  p-id="4387"
                  fill="#666"
                ></path>
                <path
                  d="M512 274.285714m-73.142857 0a40 40 0 1 0 146.285714 0 40 40 0 1 0-146.285714 0Z"
                  p-id="4388"
                  fill="#666"
                ></path></svg
              >注意: 用户手机号修改成功后，需扫描企业二维码进入;
              <div @click="showCode = true" style="color: red">
                点击显示企业二维码
              </div>
            </i>
          </div>
          <Drawer title="企业二维码" placement="top" v-model="showCode">
            <div
              style="
                margin: 0 auto;
                width: 100;
                display: flex;
                justify-content: center;
              "
            >
              <img style="height: 180px" src="../../assets/qrCode.png" alt="" />
            </div>
          </Drawer>
        </div>
      </div>
    </div>

    <no-pass-com v-show="!isUserPm"> </no-pass-com>
  </div>
</template>

<script>
// @ is an alias to /src
import { wxConPost, getUserPm } from "../../api/httpApi";
import commonTop from "../../components/commonTop.vue";
import noPassCom from "../../components/noPassCom.vue";
import diaLogliu from "../../components/dialogLiu.vue";
import loadingCmp from "../../components/loadingCmp.vue";
import deptTree from "../../components/deptTree.vue";

import userList from "../../components/userList.vue";

export default {
  name: "contentStats",
  components: {
    loadingCmp,
    noPassCom,
    deptTree,
    diaLogliu,
    userList,
    commonTop,
  },

  data() {
    return {
      editModal: false, // 显示提示框

      isUserPm: false,
      showLoad: true, // 显示加载框

      showModal: false, // 显示部门弹框
      showCode: false,

      searchVal: "",
      mobile: "", // 输入用户基本信息
      wxUser: {},
      searchUserStatus: true, // 显示用户列表 | 显示编辑用户
      toSearchData: false, // 用户组件是否查询

      dailogshow: false, // 提示框显示
      duration: 1000,
      dailogoptions: {
        content: "",
        autoClose: true,
        textColor: "#ccc",
      }, // 提示框选项
    };
  },

  methods: {
    editUserCofirm() {
      if (this.wxUser.mobile == this.mobile) {
        this.$Message.info("请输入不同手机号");
        return;
      }
      if (!this.wxUser.mobile) {
        this.$Message.info("请录入手机号");
        return;
      }

      if (!this.regMobile(this.mobile)) {
        this.$Message.info("手机号不合法");
        return;
      }
      this.editModal = true;
    },
    // 搜索用户组件框方法
    searchBack() {
      this.$router.replace("/statsmain");
    },
    chooseItem(wxUser) {
      this.wxUser = wxUser;
      this.searchUserStatus = false;
    },

    back() {
      this.$router.push({ path: "/statsmain" });
    },
    regMobile(str) {
      return str.match(/^(0|86|17951)?1[0-9]{10}$/);
    },
    async editMobile() {
      this.editModal = false;
      if (this.wxUser.mobile == this.mobile) {
        this.$Message.info("请输入不同手机号");
        return;
      }
      if (!this.wxUser.mobile) {
        this.$Message.info("请录入手机号");
        return;
      }

      if (!this.regMobile(this.mobile)) {
        this.$Message.info("手机号不合法");
        return;
      }

      const query = {
        userid: this.wxUser.userid, // 用户userid
        mobile: this.mobile, // 新的手机号or领导邮箱
      };

      try {
        this.showLoad = true;
        const result = await wxConPost(
          "/services/gttt-user-mgt//user/changeMobile",
          query
        );

        if (!result.err) {
          this.$Message.info("手机号修改成功");
          this.wxUser = {};
          this.mobile = "";
        } else {
          this.$Notice.warning({
            title: result.err,
          });
        }
        this.showLoad = false;
      } catch (e) {
        console.log("=----", e);
        this.$Notice.warning({
          title: "修改失败，请稍后重试",
        });
        this.showLoad = false;
      }
    },
  },
  async created() {
    const userPm = getUserPm("通讯录管理");
    const _curUserZdTxlPm = _.get(userPm, ["pmValue", "txl"], []);
    if ((userPm && !_.isEmpty(_curUserZdTxlPm)) || userPm.pmSuper) {
      this.isUserPm = true;
    } else {
      this.isUserPm = false;
    }
    this.showLoad = false;
  },
  watch: {
    mobile: function (val) {
      if (this.mobile.length == 11) {
        const _regMobile = this.regMobile(this.mobile);

        if (!_regMobile) {
          this.dailogshow = true;
          this.dailogoptions.content = "手机号不合法";
          this.dailogoptions.textColor = "white";
          setTimeout(() => {
            this.dailogshow = false;
          }, 2000);
        }
      }
    },
  },
};
</script>

<style scoped>
.formItem {
  margin-top: 3px;
  padding: 6px 10px;
  margin: 5px 0;
  box-shadow: 0 1px 6px rgb(0 0 0 / 8%);
  display: flex;
  justify-content: space-between;
  min-height: 41px;
  line-height: 41px;
  position: relative;
}
.formItem .formItemTitle {
  font-weight: bold;
  color: #2d4761;
}
.formItem .detail {
  width: 80%;
}
</style>
